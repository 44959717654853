import { useTranslation } from "react-i18next";
import { Business, CalendarIcon, PaymentIcon, User } from "svg";
import { AdvantageBoxType } from "types";

const AdvantageSection = () => {
  const { t } = useTranslation();

  const boxes: AdvantageBoxType[] = [
    {
      title: t("satisfied-customer"),
      logo: <User />,
      amount: "32067",
    },
    {
      title: t("outlets"),
      logo: <Business />,
      amount: "5",
    },
    {
      title: t("paid-amount"),
      logo: <PaymentIcon />,
      amount: "2’800’843 ₾",
    },
    {
      title: t("successful-year"),
      logo: <CalendarIcon />,
      amount: "12",
    },
  ];

  return (
    <div className="w-full bg-[#F3F3F3] flex flex-col items-center">
      <div className="w-[111px] h-[1px] bg-black mt-9 lg:hidden"></div>
      <h1 className=" text-[32px] text-[#707070] mt-9 panton lg:mt-28">
        {t("advantages")}
      </h1>
      <div className="grid grid-cols-2 gap-y-6 items-center mt-8 lg:flex lg:gap-x-14">
        {boxes.map((box) => {
          const [topText, bottomText] = box.title.split(" ");
          return (
            <div key={box.title} className="flex flex-col items-center">
              {box.logo}
              <span className="text-[26px] text-[#006A9F]">{box.amount}</span>
              <span className="text-xs text-[#707070] mt-2">{topText}</span>
              <span className="text-xs text-[#707070]">{bottomText}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdvantageSection;
