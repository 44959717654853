import { ByOnline, Mist, Nature, Parks } from "assets";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const SliderComponent = () => {
  return (
    <div className="mt-5 w-full relative lg:mt-0">
      <Splide
        options={{
          rewind: true,
          width: "100%",
          breakpoints: {
            1920: {
              arrows: true,
              height: "500px",
            },
            768: {
              arrows: false,
              height: "266px",
            },
          },
        }}
        aria-label="React Splide Example"
      >
        <SplideSlide>
          <img src={Parks} alt="mist" />
        </SplideSlide>
        <SplideSlide>
          <img src={Parks} alt="parks" />
        </SplideSlide>
        <SplideSlide>
          <img src={Parks} alt="parks" />
        </SplideSlide>
      </Splide>
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
        <img src={ByOnline} alt="" />
      </div>
      <div className="w-full h-1 bg-blood -mt-2"></div>
    </div>
  );
};

export default SliderComponent;
