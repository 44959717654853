import { useTranslation } from "react-i18next";
import DragComponent from "./DragComponent";

const News = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-[#F3F3F3] flex flex-col items-center">
      <div className="w-[111px] h-[1px] bg-black mt-9 lg:hidden"></div>
      <h1 className=" text-[32px] text-[#707070] mt-9 panton lg:mt-28">
        {t("news")}
      </h1>
      <DragComponent />
      <span className="text-[#707070] text-[14px] mt-4 mb-9">
        {t("all-news") + " >"}
      </span>
    </div>
  );
};

export default News;
