const Case = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <path
        id="Path_233"
        data-name="Path 233"
        d="M20,7H16V5L14,3H10L8,5V7H4A2.006,2.006,0,0,0,2,9v5a1.984,1.984,0,0,0,1,1.73V19a1.993,1.993,0,0,0,2,2H19a1.993,1.993,0,0,0,2-2V15.72A2.013,2.013,0,0,0,22,14V9A2.006,2.006,0,0,0,20,7ZM10,5h4V7H10ZM4,9H20v5H15V11H9v3H4Zm9,6H11V13h2Zm6,4H5V16H9v1h6V16h4Z"
        transform="translate(-2 -3)"
        fill="#ee303c"
      />
    </svg>
  );
};

export default Case;
