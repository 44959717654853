import { ContentBoxType } from "types";

const ContentBox: React.FC<ContentBoxType> = (props) => {
  return (
    <div className="bg-white w-40 h-28 rounded-xl flex flex-col justify-center items-center px-10 text-center lg:w-64 lg:h-44">
      {props.children}
      <span className="text-xs text-[#707070] mt-2 lg:text-lg">
        {props.title}
      </span>
    </div>
  );
};

export default ContentBox;
