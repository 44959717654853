const RedPhone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23.999"
      viewBox="0 0 24 23.999"
    >
      <path
        id="Subtraction_7"
        data-name="Subtraction 7"
        d="M979-140a11.923,11.923,0,0,1-8.486-3.514A11.921,11.921,0,0,1,967-152a11.921,11.921,0,0,1,3.515-8.485A11.923,11.923,0,0,1,979-164a11.921,11.921,0,0,1,8.485,3.514A11.921,11.921,0,0,1,991-152a11.921,11.921,0,0,1-3.514,8.485A11.921,11.921,0,0,1,979-140Zm-5.208-17.859a.652.652,0,0,0-.652.651,11,11,0,0,0,3.242,7.827,11,11,0,0,0,7.827,3.241.652.652,0,0,0,.651-.652v-2.279a.651.651,0,0,0-.651-.651,7.421,7.421,0,0,1-2.325-.371.671.671,0,0,0-.2-.031.661.661,0,0,0-.461.187l-1.433,1.433a9.871,9.871,0,0,1-4.29-4.284l1.432-1.439a.639.639,0,0,0,.163-.658,7.41,7.41,0,0,1-.371-2.324.652.652,0,0,0-.652-.651Z"
        transform="translate(-967 164)"
        fill="#ee303c"
      />
    </svg>
  );
};

export default RedPhone;
