import { Mist, Nature, Parks } from "assets";
import i18next from "i18next";
import { News } from "types";
import Draggable from "react-draggable";

const DragComponent = () => {
  const language = i18next.language;
  console.log(language);

  const news: News[] = [
    {
      poster: Mist,
      title: {
        eng: "10 common myths about living in the countryside",
        ka: "ქალაქგარეთ ცხოვრების 10 გავრცელებული მითი",
      },
      date: {
        eng: "6 november",
        ka: "6 ნოემბერი",
      },
    },
    {
      poster: Nature,
      title: {
        eng: "A healthy eating secret from South Asia",
        ka: "ჯანსაღი კვების საიდუმლო სამხრეთ აზიიდან",
      },
      date: {
        eng: "28 october",
        ka: "28 ოქტომბერი",
      },
    },
    {
      poster: Parks,
      title: {
        eng: "Modern methods of car interior maintenance",
        ka: "ავტომობილის სალონის მოვლის თანამედროვე ხერხები",
      },
      date: {
        eng: "12 october",
        ka: "12 ოქტომბერი",
      },
    },
  ];

  return (
    <div className="w-full mt-4 px-9 lg:flex lg:justify-center">
      <Draggable axis="x">
        <div className="flex gap-x-3 relative w-[855px]">
          {news.map((info) => (
            <div
              className="bg-white rounded-xl w-[277px] "
              key={info.title.eng}
            >
              <img
                src={info.poster}
                alt={language === "geo" ? info.title.ka : info.title.eng}
                className=" w-full h-[166px] rounded-t-xl"
              />
              <div className="w-full p-4">
                <p className="text-[#707070] text-[14px] font-bold">
                  {language === "geo" ? info.title.ka : info.title.eng}
                </p>
                <span className="mt-4 text-[#D0D0D0] text-xs">
                  {language === "geo" ? info.date.ka : info.date.eng}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Draggable>
    </div>
  );
};

export default DragComponent;
