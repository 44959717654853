const PhoneDesktop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.004"
      height="16"
      viewBox="0 0 16.004 16"
    >
      <path
        id="phone"
        d="M12.432,12.432c-1.584,1.582-3.418,3.1-4.143,2.372-1.037-1.037-1.677-1.942-3.966-.1s-.53,3.065.475,4.069c1.16,1.16,5.486.062,9.761-4.213s5.37-8.6,4.208-9.761c-1.005-1.007-2.226-2.763-4.064-.475s-.937,2.928.1,3.966C15.528,9.014,14.014,10.848,12.432,12.432Z"
        transform="translate(19.204 -3.201) rotate(90)"
        fill="#d0d0d0"
      />
    </svg>
  );
};

export default PhoneDesktop;
