const Flight = () => {
  return (
    <svg
      id="flight_takeoff_black_48dp"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30.093"
      height="30.093"
      viewBox="0 0 30.093 30.093"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#58b0e3" />
          <stop offset="1" stopColor="#005287" />
        </linearGradient>
      </defs>
      <path
        id="Path_28"
        data-name="Path 28"
        d="M0,0H30.093V30.093H0Z"
        fill="none"
      />
      <path
        id="Path_29"
        data-name="Path 29"
        d="M2.668,22.917H26.491v2.508H2.668ZM27.206,11.181A1.876,1.876,0,0,0,24.9,9.852l-6.658,1.781L9.589,3.57l-2.42.639L12.36,13.2,6.128,14.867l-2.47-1.931-1.818.489,3.248,5.63L25.864,13.5a1.909,1.909,0,0,0,1.342-2.32Z"
        transform="translate(0.467 0.906)"
        fill="#005287"
      />
    </svg>
  );
};

export default Flight;
