import {
  AdvantageSection,
  DesktopMenu,
  Footer,
  Header,
  MainContentSection,
  Menu,
  News,
  SliderComponent,
} from "components";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_geo from "./locales/geo.json";
import common_eng from "./locales/eng.json";
import { useState } from "react";
import { AppStore } from "svg";
import { GooglePlay } from "assets";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "geo",
    debug: false,
    resources: {
      en: { translation: common_eng },
      geo: { translation: common_geo },
    },
  });

function App() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  return (
    <div className="h-full overflow-x-hidden">
      {showMenu ? <Menu setShowMenu={setShowMenu} /> : null}
      <Header setShowMenu={setShowMenu} />
      <DesktopMenu />
      <SliderComponent />
      <MainContentSection />
      <AdvantageSection />
      <News />
      <Footer />
      <div className="hidden mt-1 mb-14 items-center pl-20 lg:flex xl:pl-48 2xl:pl-64">
        <AppStore />
        <img src={GooglePlay} alt="" />
        <p className="ml-[107px] text-[#707070] text-[14px]">
          Unison © All rights reserved, 2022
        </p>
      </div>
    </div>
  );
}

export default App;
