const MenuIcon: React.FC<{ onClick: (bool: boolean) => void}> = (props) => {
    return <svg onClick={() => props.onClick(true)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="Group_110" data-name="Group 110" transform="translate(-329 -49)">
      <rect id="Rectangle_60" data-name="Rectangle 60" width="25" height="5" rx="2.5" transform="translate(329 49)" fill="#ee303c"/>
      <rect id="Rectangle_61" data-name="Rectangle 61" width="25" height="5" rx="2.5" transform="translate(329 59)" fill="#ee303c"/>
      <rect id="Rectangle_62" data-name="Rectangle 62" width="25" height="5" rx="2.5" transform="translate(329 69)" fill="#ee303c"/>
    </g>
  </svg>
}

export default MenuIcon;