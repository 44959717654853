const AppStore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119.664"
      height="40"
      viewBox="0 0 119.664 40"
    >
      <g
        id="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917"
        transform="translate(0 0)"
      >
        <g id="Group_31" data-name="Group 31">
          <g id="Group_29" data-name="Group 29">
            <path
              id="Path_49"
              data-name="Path 49"
              d="M110.135,0H9.535C9.168,0,8.806,0,8.44,0c-.306,0-.61.008-.919.013a13.215,13.215,0,0,0-2,.177,6.665,6.665,0,0,0-1.9.627A6.438,6.438,0,0,0,2,2,6.258,6.258,0,0,0,.819,3.618a6.6,6.6,0,0,0-.625,1.9,12.993,12.993,0,0,0-.179,2C.006,7.83,0,8.138,0,8.444V31.559c0,.31.006.611.015.922a12.993,12.993,0,0,0,.179,2,6.588,6.588,0,0,0,.625,1.9A6.208,6.208,0,0,0,2,38,6.274,6.274,0,0,0,3.616,39.18a6.7,6.7,0,0,0,1.9.631,13.455,13.455,0,0,0,2,.177c.309.007.613.011.919.011.366,0,.728,0,1.095,0h100.6c.359,0,.725,0,1.084,0,.3,0,.617,0,.922-.011a13.279,13.279,0,0,0,2-.177,6.8,6.8,0,0,0,1.908-.631A6.278,6.278,0,0,0,117.666,38a6.4,6.4,0,0,0,1.182-1.614,6.6,6.6,0,0,0,.619-1.9,13.506,13.506,0,0,0,.186-2c0-.311,0-.611,0-.922.008-.363.008-.725.008-1.094V9.536c0-.366,0-.729-.008-1.092,0-.307,0-.614,0-.921a13.506,13.506,0,0,0-.186-2,6.618,6.618,0,0,0-.619-1.9,6.466,6.466,0,0,0-2.8-2.8,6.768,6.768,0,0,0-1.908-.627,13.044,13.044,0,0,0-2-.177c-.3,0-.617-.011-.922-.013-.359,0-.725,0-1.084,0Z"
              fill="#a6a6a6"
            />
            <path
              id="Path_50"
              data-name="Path 50"
              d="M8.445,39.125c-.3,0-.6,0-.9-.011a12.688,12.688,0,0,1-1.869-.163A5.884,5.884,0,0,1,4.015,38.4a5.406,5.406,0,0,1-1.4-1.017A5.321,5.321,0,0,1,1.6,35.99a5.722,5.722,0,0,1-.543-1.657,12.413,12.413,0,0,1-.167-1.875c-.006-.211-.015-.913-.015-.913V8.444s.009-.691.015-.895a12.37,12.37,0,0,1,.166-1.872A5.755,5.755,0,0,1,1.6,4.016a5.373,5.373,0,0,1,1.015-1.4A5.565,5.565,0,0,1,4.014,1.6a5.823,5.823,0,0,1,1.653-.544A12.586,12.586,0,0,1,7.543.887l.9-.012H111.214l.913.013a12.385,12.385,0,0,1,1.858.163,5.938,5.938,0,0,1,1.671.548,5.594,5.594,0,0,1,2.415,2.42,5.763,5.763,0,0,1,.535,1.649,13,13,0,0,1,.174,1.887c0,.283,0,.587,0,.89.008.375.008.732.008,1.092V30.465c0,.363,0,.718-.008,1.075,0,.325,0,.623,0,.93a12.73,12.73,0,0,1-.171,1.854,5.739,5.739,0,0,1-.54,1.67,5.48,5.48,0,0,1-1.016,1.386,5.413,5.413,0,0,1-1.4,1.022,5.862,5.862,0,0,1-1.668.55,12.543,12.543,0,0,1-1.869.163c-.293.007-.6.011-.9.011l-1.084,0Z"
            />
          </g>
          <g id="_Group_" data-name="&lt;Group&gt;">
            <g id="_Group_2" data-name="&lt;Group&gt;">
              <g id="_Group_3" data-name="&lt;Group&gt;">
                <path
                  id="_Path_"
                  data-name="&lt;Path&gt;"
                  d="M24.769,20.3a4.949,4.949,0,0,1,2.357-4.152,5.066,5.066,0,0,0-3.991-2.158c-1.679-.176-3.307,1-4.163,1-.872,0-2.19-.987-3.608-.958a5.315,5.315,0,0,0-4.473,2.728c-1.934,3.348-.491,8.269,1.361,10.976.927,1.325,2.01,2.806,3.428,2.753,1.387-.058,1.905-.884,3.579-.884,1.659,0,2.145.884,3.591.851,1.488-.024,2.426-1.331,3.321-2.669A10.962,10.962,0,0,0,27.688,24.7,4.782,4.782,0,0,1,24.769,20.3Z"
                  fill="#fff"
                />
                <path
                  id="_Path_2"
                  data-name="&lt;Path&gt;"
                  d="M22.037,12.211A4.872,4.872,0,0,0,23.152,8.72a4.957,4.957,0,0,0-3.208,1.66A4.636,4.636,0,0,0,18.8,13.741,4.1,4.1,0,0,0,22.037,12.211Z"
                  fill="#fff"
                />
              </g>
            </g>
            <g id="Group_30" data-name="Group 30">
              <path
                id="Path_51"
                data-name="Path 51"
                d="M42.3,27.14H37.569L36.432,30.5h-2l4.483-12.418h2.083L45.477,30.5H43.438Zm-4.243-1.549h3.752l-1.85-5.447H39.91Z"
                fill="#fff"
              />
              <path
                id="Path_52"
                data-name="Path 52"
                d="M55.16,25.97c0,2.813-1.506,4.621-3.778,4.621a3.069,3.069,0,0,1-2.849-1.584H48.49v4.484H46.631V21.442h1.8v1.506h.034a3.212,3.212,0,0,1,2.883-1.6C53.645,21.348,55.16,23.164,55.16,25.97Zm-1.91,0c0-1.833-.947-3.038-2.393-3.038-1.42,0-2.375,1.23-2.375,3.038,0,1.824.955,3.046,2.375,3.046C52.3,29.016,53.25,27.819,53.25,25.97Z"
                fill="#fff"
              />
              <path
                id="Path_53"
                data-name="Path 53"
                d="M65.125,25.97c0,2.813-1.506,4.621-3.778,4.621A3.069,3.069,0,0,1,58.5,29.007h-.043v4.484H56.6V21.442h1.8v1.506h.034a3.212,3.212,0,0,1,2.883-1.6C63.61,21.348,65.125,23.164,65.125,25.97Zm-1.91,0c0-1.833-.947-3.038-2.393-3.038-1.42,0-2.375,1.23-2.375,3.038,0,1.824.955,3.046,2.375,3.046,1.445,0,2.393-1.2,2.393-3.046Z"
                fill="#fff"
              />
              <path
                id="Path_54"
                data-name="Path 54"
                d="M71.71,27.036c.138,1.231,1.334,2.04,2.969,2.04,1.566,0,2.693-.809,2.693-1.919,0-.964-.68-1.541-2.289-1.937l-1.609-.388c-2.28-.551-3.339-1.617-3.339-3.348,0-2.143,1.867-3.614,4.519-3.614,2.624,0,4.423,1.472,4.483,3.614H77.261c-.112-1.239-1.137-1.987-2.634-1.987s-2.521.757-2.521,1.858c0,.878.654,1.395,2.255,1.79l1.368.336c2.548.6,3.606,1.626,3.606,3.442,0,2.323-1.851,3.778-4.794,3.778-2.754,0-4.613-1.421-4.733-3.667Z"
                fill="#fff"
              />
              <path
                id="Path_55"
                data-name="Path 55"
                d="M83.346,19.3v2.143h1.722v1.472H83.346v4.991c0,.775.345,1.137,1.1,1.137A5.807,5.807,0,0,0,85.059,29v1.463a5.1,5.1,0,0,1-1.032.086c-1.833,0-2.548-.688-2.548-2.444V22.914H80.163V21.442h1.316V19.3Z"
                fill="#fff"
              />
              <path
                id="Path_56"
                data-name="Path 56"
                d="M86.065,25.97c0-2.849,1.678-4.639,4.294-4.639s4.295,1.79,4.295,4.639-1.661,4.639-4.295,4.639S86.065,28.826,86.065,25.97Zm6.7,0c0-1.954-.9-3.107-2.4-3.107s-2.4,1.162-2.4,3.107c0,1.962.895,3.106,2.4,3.106s2.4-1.145,2.4-3.106Z"
                fill="#fff"
              />
              <path
                id="Path_57"
                data-name="Path 57"
                d="M96.186,21.442h1.772v1.541H98a2.159,2.159,0,0,1,2.178-1.636,2.866,2.866,0,0,1,.637.069v1.738a2.6,2.6,0,0,0-.835-.112,1.873,1.873,0,0,0-1.937,2.083V30.5H96.186Z"
                fill="#fff"
              />
              <path
                id="Path_58"
                data-name="Path 58"
                d="M109.384,27.837c-.25,1.644-1.851,2.771-3.9,2.771-2.634,0-4.269-1.765-4.269-4.6s1.644-4.682,4.19-4.682c2.5,0,4.08,1.721,4.08,4.466v.637h-6.395v.112a2.358,2.358,0,0,0,2.436,2.564,2.048,2.048,0,0,0,2.091-1.273Zm-6.282-2.7h4.526a2.177,2.177,0,0,0-2.221-2.3,2.292,2.292,0,0,0-2.306,2.3Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <g id="_Group_4" data-name="&lt;Group&gt;">
          <g id="Group_32" data-name="Group 32">
            <path
              id="Path_59"
              data-name="Path 59"
              d="M37.826,8.731A2.64,2.64,0,0,1,40.634,11.7c0,1.906-1.03,3-2.808,3H35.671V8.731ZM36.6,13.854h1.125a1.876,1.876,0,0,0,1.968-2.146,1.881,1.881,0,0,0-1.968-2.134H36.6Z"
              fill="#fff"
            />
            <path
              id="Path_60"
              data-name="Path 60"
              d="M41.681,12.444a2.133,2.133,0,1,1,4.247,0,2.134,2.134,0,1,1-4.247,0Zm3.333,0c0-.976-.438-1.547-1.208-1.547s-1.207.571-1.207,1.547.435,1.55,1.207,1.55,1.208-.57,1.208-1.55Z"
              fill="#fff"
            />
            <path
              id="Path_61"
              data-name="Path 61"
              d="M51.573,14.7h-.922l-.931-3.316h-.07L48.724,14.7h-.913l-1.241-4.5h.9l.807,3.436h.066l.926-3.436h.853l.926,3.436h.07l.8-3.436h.889Z"
              fill="#fff"
            />
            <path
              id="Path_62"
              data-name="Path 62"
              d="M53.854,10.195h.855v.715h.066a1.348,1.348,0,0,1,1.344-.8,1.465,1.465,0,0,1,1.559,1.675V14.7h-.889V12.006c0-.724-.314-1.083-.972-1.083a1.033,1.033,0,0,0-1.075,1.141V14.7h-.889Z"
              fill="#fff"
            />
            <path
              id="Path_63"
              data-name="Path 63"
              d="M59.094,8.437h.889V14.7h-.889Z"
              fill="#fff"
            />
            <path
              id="Path_64"
              data-name="Path 64"
              d="M61.218,12.444a2.133,2.133,0,1,1,4.248,0,2.134,2.134,0,1,1-4.248,0Zm3.333,0c0-.976-.438-1.547-1.208-1.547s-1.207.571-1.207,1.547.435,1.55,1.207,1.55,1.208-.57,1.208-1.55Z"
              fill="#fff"
            />
            <path
              id="Path_65"
              data-name="Path 65"
              d="M66.4,13.424c0-.811.6-1.278,1.675-1.344l1.22-.07v-.389c0-.476-.314-.744-.922-.744-.5,0-.84.182-.938.5h-.86c.091-.773.818-1.27,1.84-1.27,1.129,0,1.766.562,1.766,1.513V14.7h-.855v-.633h-.07a1.515,1.515,0,0,1-1.353.707,1.36,1.36,0,0,1-1.5-1.348ZM69.3,13.04v-.376l-1.1.07c-.62.042-.9.252-.9.649s.352.641.835.641A1.061,1.061,0,0,0,69.3,13.04Z"
              fill="#fff"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M71.348,12.444c0-1.423.731-2.324,1.869-2.324a1.484,1.484,0,0,1,1.381.79h.066V8.437h.889V14.7H74.7v-.711h-.07a1.563,1.563,0,0,1-1.414.786C72.072,14.772,71.348,13.871,71.348,12.444Zm.918,0c0,.955.45,1.53,1.2,1.53s1.212-.583,1.212-1.526-.468-1.53-1.212-1.53-1.2.579-1.2,1.526Z"
              fill="#fff"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M79.23,12.444a2.133,2.133,0,1,1,4.247,0,2.134,2.134,0,1,1-4.247,0Zm3.333,0c0-.976-.438-1.547-1.208-1.547s-1.207.571-1.207,1.547.435,1.55,1.207,1.55S82.563,13.424,82.563,12.444Z"
              fill="#fff"
            />
            <path
              id="Path_68"
              data-name="Path 68"
              d="M84.669,10.195h.855v.715h.066a1.348,1.348,0,0,1,1.344-.8,1.465,1.465,0,0,1,1.559,1.675V14.7h-.889V12.006c0-.724-.314-1.083-.972-1.083a1.033,1.033,0,0,0-1.075,1.141V14.7h-.889Z"
              fill="#fff"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M93.515,9.074v1.142h.976v.749h-.976v2.315c0,.472.194.678.637.678a2.966,2.966,0,0,0,.339-.021v.74a2.915,2.915,0,0,1-.483.045c-.988,0-1.382-.348-1.382-1.216V10.964h-.715v-.749h.715V9.074Z"
              fill="#fff"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M95.7,8.437h.881v2.481h.07a1.386,1.386,0,0,1,1.373-.807,1.483,1.483,0,0,1,1.551,1.679V14.7h-.89V12.01c0-.719-.335-1.083-.963-1.083a1.052,1.052,0,0,0-1.134,1.142V14.7H95.7Z"
              fill="#fff"
            />
            <path
              id="Path_71"
              data-name="Path 71"
              d="M104.761,13.482a1.828,1.828,0,0,1-1.951,1.3,2.045,2.045,0,0,1-2.08-2.324,2.077,2.077,0,0,1,2.076-2.353c1.253,0,2.009.856,2.009,2.27v.31h-3.18v.05a1.19,1.19,0,0,0,1.2,1.29,1.079,1.079,0,0,0,1.071-.546Zm-3.126-1.451h2.274a1.086,1.086,0,0,0-1.108-1.167,1.152,1.152,0,0,0-1.166,1.167Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AppStore;
