import { useTranslation } from "react-i18next";
import { CarIcon, Cart, Flight, Health, HomeIcon, LiabilityIcon } from "svg";
import { ContentBoxType } from "types";
import ContentBox from "./ContentBox";

const MainCOntentSection = () => {
  const { t } = useTranslation();

  const boxes: ContentBoxType[] = [
    {
      title: t("property-insurance"),
      children: <HomeIcon />,
    },
    {
      title: t("health-insurance"),
      children: <Health />,
    },
    {
      title: t("auto-insurance"),
      children: <CarIcon />,
    },
    {
      title: t("liability-insurance"),
      children: <LiabilityIcon />,
    },
    {
      title: t("travel-insurance"),
      children: <Flight />,
    },
  ];

  return (
    <section className="w-full flex flex-col items-center bg-[#F3F3F3]">
      <div className="flex flex-wrap gap-x-[10px] gap-y-[10px] -mt-5 z-10 justify-center">
        {boxes.map((box) => (
          <ContentBox title={box.title} key={box.title}>
            {box.children}
          </ContentBox>
        ))}
        <div className="lg:hidden">
          <ContentBox title={t("shop-online")}>
            <Cart />
          </ContentBox>
        </div>
      </div>
      <button className="w-[330px] h-[71px] bg-blood rounded-xl text-[22px] text-white mt-3 shadow-buttonShadow lg:hidden">
        SOS
      </button>
    </section>
  );
};

export default MainCOntentSection;
