const Phone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.946"
      height="34.946"
      viewBox="0 0 34.946 34.946"
    >
      <g id="perm_phone_msg_black_24dp" transform="translate(0)">
        <path
          id="Path_39"
          data-name="Path 39"
          d="M0,0H34.946V34.946H0Z"
          transform="translate(0)"
          fill="none"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M27.753,21.2a16.541,16.541,0,0,1-5.2-.83,1.486,1.486,0,0,0-1.485.349l-3.2,3.2a21.949,21.949,0,0,1-9.6-9.581l3.2-3.218a1.429,1.429,0,0,0,.364-1.471,16.541,16.541,0,0,1-.83-5.2A1.46,1.46,0,0,0,9.552,3h-5.1A1.46,1.46,0,0,0,3,4.456,24.751,24.751,0,0,0,27.753,29.209a1.46,1.46,0,0,0,1.456-1.456v-5.1A1.46,1.46,0,0,0,27.753,21.2ZM16.1,3V17.561l4.368-4.368h8.736V3Z"
          transform="translate(1.368 1.368)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Phone;
