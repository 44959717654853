import { Facebook, GooglePlay, Instagram, Linkedin, Youtube } from "assets";
import { useTranslation } from "react-i18next";
import { AppStore, Logo } from "svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="p-9 lg:flex">
      <div className="lg:flex lg:flex-col lg:items-end xl:ml-28 2xl:ml-44">
        <Logo />
        <ul className="mt-6 flex flex-wrap gap-x-14 gap-y-2 lg:flex-col lg:gap-y-5">
          <li className="text-base text-[#707070]">{t("about-us")}</li>
          <li className="text-base text-[#707070]">{t("career")}</li>
          <li className="text-base text-[#707070]">{t("partners")}</li>
        </ul>
      </div>
      <div className="mt-5 lg:mt-0 lg:ml-20 2xl:ml-36">
        <h3 className="text-base text-[#707070] font-bold">{t("info")}</h3>
        <ul className="mt-2 flex flex-wrap gap-x-14 gap-y-2 lg:flex-col lg:gap-y-5 lg:mt-5">
          <li className="text-base text-[#707070]">{t("news")}</li>
          <li className="text-base text-[#707070]">{t("agreements")}</li>
          <li className="text-base text-[#707070] w-32 lg:w-[204px]">
            {t("information-sheet")}
          </li>
          <li className="text-base text-[#707070] w-32 -ml-11 lg:-ml-0 lg:w-[224px]">
            {t("medical-providers")}
          </li>
        </ul>
      </div>
      <div className="mt-5 lg:mt-0 lg:ml-16 2xl:ml-28 lg:flex lg:flex-col">
        <h3 className="text-base text-[#707070] font-bold">{t("help")}</h3>
        <ul className="mt-2 flex flex-wrap gap-x-14 gap-y-2 lg:flex-col  lg:gap-y-5 lg:mt-5">
          <li className="text-base text-[#707070]">{t("contact")}</li>
          <li className="text-base text-[#707070] ml-2 lg:ml-0 lg:w-[143px]">
            {t("data-protection")}
          </li>
          <li className="text-base text-[#707070] w-32 lg:w-[153px]">
            {t("user-voice")}
          </li>
          <li className="text-base text-[#707070] w-36 -ml-11 lg:-ml-0 lg:w-[219px]">
            {t("FAQ")}
          </li>
        </ul>
      </div>

      <div className="mt-7 flex items-center gap-x-5 lg:hidden">
        <AppStore />
        <img src={GooglePlay} alt="" />
      </div>
      <div className="mt-5 lg:mt-0 lg:w-[172px] lg:ml-8">
        <h3 className="text-base text-[#707070] font-bold">
          {t("social-networks")}
        </h3>
        <div className="flex items-center gap-x-7 lg:flex-col lg:items-start lg:mt-4 lg:gap-y-3">
          <div className="flex items-center gap-x-3">
            <img src={Facebook} alt="" />
            <span className="text-[#707070] text-base">Facebook</span>
          </div>
          <div className="flex items-center gap-x-3">
            <img src={Instagram} alt="" />
            <span className="text-[#707070] text-base">Instagram</span>
          </div>
        </div>
        <div className="flex items-center gap-x-10 mt-4 lg:flex-col lg:items-start lg:mt-2 lg:gap-y-3">
          <div className="flex items-center gap-x-3">
            <img src={Youtube} alt="" />
            <span className="text-[#707070] text-base">Youtube</span>
          </div>
          <div className="flex items-center gap-x-3">
            <img src={Linkedin} alt="" />
            <span className="text-[#707070] text-base">Linkedin</span>
          </div>
        </div>
        <p className="mt-5 text-[#707070] text-[14px] lg:hidden">
          Unison © All rights reserved, 2022
        </p>
      </div>
    </footer>
  );
};

export default Footer;
