import { England, Georgia } from "assets";
import i18next from "i18next";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Select, { ActionMeta, GroupBase, StylesConfig } from "react-select";

type Option = { value: string; label: ReactNode };

const customStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#EE303C" : "#F2F2F2",
    backgroundColor: state.isSelected ? "#006A9F" : "#242424",
  }),
  control: () => ({
    backgroundColor: "transparent",
    display: "flex",
    color: "#F2F2F2",
    border: "none",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (styles) => ({ ...styles, color: "#F2F2F2" }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const color = "#F2F2F2";
    const fontSize = "16px";
    const lineHeight = "18.79px";
    const letterSpacing = "-0.2px";
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
      color,
      fontSize,
      lineHeight,
      letterSpacing,
    };
  },
};

const Language = () => {
  const { t } = useTranslation();

  const options = [
    {
      value: "geo",
      label: (
        <div className="flex items-center gap-x-2 text-base text-[#F2F2F2]">
          <img src={Georgia} alt="" />
          <span>{t("georgian")}</span>
        </div>
      ),
    },
    {
      value: "en",
      label: (
        <div className="flex items-center gap-x-2 text-base text-[#F2F2F2]">
          <img src={England} alt="" />
          <span>{t("english")}</span>
        </div>
      ),
    },
  ];

  const languageChangeHandler = (
    option: Option | null,
    actionMeta: ActionMeta<Option>
  ) => {
    i18next.changeLanguage(option?.value);
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      defaultValue={{
        value: "geo",
        label: (
          <div className="flex items-center gap-x-2 text-base text-[#F2F2F2]">
            <img src={Georgia} alt="" />
            <span>{t("georgian")}</span>
          </div>
        ),
      }}
      onChange={languageChangeHandler}
    />
  );
};

export default Language;
