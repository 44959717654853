const CalendarIcon = () => {
  return (
    <svg
      id="date_range_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="52.771"
      height="52.771"
      viewBox="0 0 52.771 52.771"
    >
      <path
        id="Path_46"
        data-name="Path 46"
        d="M0,0H52.771V52.771H0Z"
        fill="none"
      />
      <path
        id="Path_47"
        data-name="Path 47"
        d="M11.8,21.789h4.4v4.4H11.8ZM42.578,10.8V41.578a4.411,4.411,0,0,1-4.4,4.4H7.4a4.4,4.4,0,0,1-4.4-4.4L3.022,10.8A4.378,4.378,0,0,1,7.4,6.4H9.6V2h4.4V6.4h17.59V2h4.4V6.4h2.2A4.411,4.411,0,0,1,42.578,10.8ZM7.4,15.193H38.181V10.8H7.4ZM38.181,41.578V19.59H7.4V41.578Zm-8.8-15.392h4.4v-4.4h-4.4Zm-8.8,0h4.4v-4.4h-4.4Z"
        transform="translate(3.596 2.398)"
        fill="#006a9f"
      />
    </svg>
  );
};

export default CalendarIcon;
