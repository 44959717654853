const Close: React.FC<{ onClick: () => void }> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.213"
      height="21.213"
      viewBox="0 0 21.213 21.213"
      onClick={props.onClick}
    >
      <g
        id="Group_110"
        data-name="Group 110"
        transform="translate(-330.893 -50.893)"
      >
        <rect
          id="Rectangle_60"
          data-name="Rectangle 60"
          width="25"
          height="5"
          rx="2.5"
          transform="translate(334.429 50.893) rotate(45)"
          fill="#ee303c"
        />
        <rect
          id="Rectangle_61"
          data-name="Rectangle 61"
          width="5"
          height="5"
          rx="2.5"
          transform="translate(339 59)"
          fill="#ee303c"
        />
        <rect
          id="Rectangle_62"
          data-name="Rectangle 62"
          width="25"
          height="5"
          rx="2.5"
          transform="translate(330.893 68.571) rotate(-45)"
          fill="#ee303c"
        />
      </g>
    </svg>
  );
};

export default Close;
