const Warning = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
    >
      <path
        id="Union_5"
        data-name="Union 5"
        d="M967-154.91V-161l8-3,8,3v6.09c0,5.05-3.41,9.76-8,10.91C970.41-145.15,967-149.86,967-154.91Zm2-4.7v4.7a9.327,9.327,0,0,0,6,8.83,9.336,9.336,0,0,0,6-8.83v-4.7l-6-2.25ZM974-150v-2h2v2Zm0-4v-5h2v5Z"
        transform="translate(-967 164)"
        fill="#ee303c"
      />
    </svg>
  );
};

export default Warning;
