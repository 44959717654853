import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  Case,
  Close,
  Human,
  LogoMenu,
  Phone,
  RedPhone,
  UserMenu,
  Warning,
} from "svg";

type PropsType = {
  setShowMenu: (bool: boolean) => void;
};

const Menu: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const language = i18next.language;
  return (
    <div className="absolute w-full bg-white z-20">
      <div className="px-9">
        <div className="flex justify-between items-center mt-11">
          <LogoMenu />
          <Close onClick={() => props.setShowMenu(false)} />
        </div>
        <div className="flex mt-12 items-center">
          <UserMenu />
          <span className="ml-5 w-10 text-[#707070] text-base">
            {t("my-cabinet")}
          </span>
          <button className="border-none outline-none cursor-pointer w-14 h-14 bg-blood rounded-xl text-white uppercase ml-auto">
            sos
          </button>
        </div>
        <div className="flex items-center mt-14">
          <Human />
          <span className="text-[#707070] text-lg ml-[18px]">
            {t("for-people")}
          </span>
          <span className="text-blood ml-auto">{">"}</span>
        </div>
        <div className="flex items-center mt-9">
          <Case />
          <span className="text-[#707070] text-lg ml-[18px]">
            {t("for-entities")}
          </span>
          <span className="text-blood ml-auto">{">"}</span>
        </div>
        <div className="flex items-center mt-9">
          <Warning />
          <span className="text-[#707070] text-lg ml-5">
            {t("compulsory-insurance")}
          </span>
          <span className="text-blood ml-auto">{">"}</span>
        </div>
        <button className="border-none outline-none bg-blood rounded-xl w-full h-16 text-white flex items-center justify-center gap-x-2 mt-[42px]">
          <Phone />
          <span className="w-24 panton">{t("call-agent")}</span>
        </button>
        <div className="mt-11 flex items-center gap-x-[50px] text-[#707070] text-base">
          <span>{t("about-us")}</span>
          <span>{t("news")}</span>
        </div>
        <div className="mt-9 flex items-center gap-x-[55px] text-[#707070] text-base">
          <span>{t("partners")}</span>
          <span>{t("contact")}</span>
        </div>
      </div>
      <div className="mt-[71px]">
        <div className="flex items-center">
          {language === "geo" ? (
            <span className="w-5 h-[14px] bg-blood rounded-r-lg"></span>
          ) : null}
          <span
            className="text-base"
            style={{
              color: language === "geo" ? "#EE303C" : "#707070",
              marginLeft: language === "geo" ? "15px" : "35px",
            }}
            onClick={() => i18next.changeLanguage("geo")}
          >
            {t("georgian")}
          </span>
        </div>
        <div className="flex items-center mt-5">
          {language === "en" ? (
            <span className="w-5 h-[14px] bg-blood rounded-r-lg"></span>
          ) : null}
          <span
            className="text-base"
            style={{
              color: language === "en" ? "#EE303C" : "#707070",
              marginLeft: language === "en" ? "15px" : "35px",
            }}
            onClick={() => i18next.changeLanguage("en")}
          >
            {t("english")}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-end w-full pr-9">
        <span className="text-[#707070] text-[14px]">{t("call-center")}</span>
        <div className="flex items-center gap-x-2 mb-[52px]">
          <RedPhone />
          <span className="text-blood text-[26px]">2 991 991</span>
        </div>
      </div>
    </div>
  );
};

export default Menu;
