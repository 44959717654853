const CarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31"
      height="30"
      viewBox="0 0 31 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#58b0e3" />
          <stop offset="1" stopColor="#005287" />
        </linearGradient>
      </defs>
      <g
        id="directions_car_filled_black_48dp"
        transform="translate(0.436 -0.041)"
      >
        <g id="Group_11" data-name="Group 11">
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="31"
            height="30"
            transform="translate(-0.436 0.041)"
            fill="none"
          />
        </g>
        <g id="Group_13" data-name="Group 13" transform="translate(3.762 6.27)">
          <g id="Group_12" data-name="Group 12" transform="translate(0 0)">
            <path
              id="Union_4"
              data-name="Union 4"
              d="M20.444,20a1.268,1.268,0,0,1-1.278-1.25V17.5H3.833v1.25A1.268,1.268,0,0,1,2.555,20H1.278A1.268,1.268,0,0,1,0,18.75v-10L2.658,1.262A1.919,1.919,0,0,1,4.472,0H18.528a1.906,1.906,0,0,1,1.814,1.262L23,8.75v10A1.268,1.268,0,0,1,21.722,20ZM2.555,15H20.444V8.75H2.555ZM3.59,6.25H19.4L18.068,2.5H4.919Zm11.743,5.625A1.917,1.917,0,1,1,17.25,13.75,1.9,1.9,0,0,1,15.333,11.875Zm-11.5,0A1.917,1.917,0,1,1,5.75,13.75,1.9,1.9,0,0,1,3.833,11.875Z"
              transform="translate(-0.198 -0.228)"
              fill="#005287"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CarIcon;
