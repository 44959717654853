const User = () => {
  return (
    <svg
      id="_24px"
      data-name="24px"
      xmlns="http://www.w3.org/2000/svg"
      width="52.771"
      height="52.771"
      viewBox="0 0 52.771 52.771"
    >
      <path
        id="Path_41"
        data-name="Path 41"
        d="M0,0H52.771V52.771H0Z"
        fill="none"
      />
      <path
        id="Path_42"
        data-name="Path 42"
        d="M23.988,2A21.988,21.988,0,1,0,45.976,23.988,22,22,0,0,0,23.988,2ZM13.148,37.8c.945-1.979,6.706-3.914,10.84-3.914S33.9,35.817,34.828,37.8a17.426,17.426,0,0,1-21.68,0Zm24.824-3.188c-3.144-3.826-10.774-5.123-13.984-5.123S13.148,30.782,10,34.608a17.59,17.59,0,1,1,27.969,0ZM23.988,10.8a7.7,7.7,0,1,0,7.7,7.7A7.676,7.676,0,0,0,23.988,10.8Zm0,10.994a3.3,3.3,0,1,1,3.3-3.3A3.294,3.294,0,0,1,23.988,21.789Z"
        transform="translate(2.398 2.398)"
        fill="#006a9f"
      />
    </svg>
  );
};

export default User;
