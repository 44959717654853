import { useTranslation } from "react-i18next";
import { LogoDesktop, Phone, PhoneDesktop } from "svg";
import UserDesktop from "svg/UserDesktop";

const DesktopMenu = () => {
  const { t } = useTranslation();
  return (
    <div className="relative hidden lg:flex w-hull h-32 bg-[#006A9F] items-center justify-between px-3 xl:px-0 xl:justify-around">
      <ul className="flex items-center gap-x-16">
        <LogoDesktop />
        <li className="text-white text-lg w-11">{t("for-people")}</li>
        <li className="text-white text-lg w-11 ml-16">{t("for-entities")}</li>
        <li className="text-white text-lg w-11 ml-16">
          {t("compulsory-insurance")}
        </li>
      </ul>
      <div className="flex items-center gap-x-3">
        <UserDesktop />
        <span className="w-24 text-xl text-white">{t("my-cabinet")}</span>
      </div>
      <div className="absolute w-40 h-32 rounded-l-xl bg-blood p-6 right-0 top-[100px] z-20">
        <Phone />
        <span className="w-24 text-white text-[11px]">{t("call-agent")}</span>
      </div>
    </div>
  );
};

export default DesktopMenu;
