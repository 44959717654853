const Business = () => {
  return (
    <svg
      id="business_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="52.771"
      height="52.771"
      viewBox="0 0 52.771 52.771"
    >
      <path
        id="Path_43"
        data-name="Path 43"
        d="M0,0H52.771V52.771H0Z"
        fill="none"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M23.988,11.8V3H2V42.578H45.976V11.8ZM10.8,38.181H6.4v-4.4h4.4Zm0-8.8H6.4v-4.4h4.4Zm0-8.8H6.4v-4.4h4.4Zm0-8.8H6.4V7.4h4.4Zm8.8,26.385h-4.4v-4.4h4.4Zm0-8.8h-4.4v-4.4h4.4Zm0-8.8h-4.4v-4.4h4.4Zm0-8.8h-4.4V7.4h4.4ZM41.578,38.181H23.988v-4.4h4.4v-4.4h-4.4v-4.4h4.4v-4.4h-4.4v-4.4h17.59Zm-4.4-17.59h-4.4v4.4h4.4Zm0,8.8h-4.4v4.4h4.4Z"
        transform="translate(2.398 3.596)"
        fill="#006a9f"
      />
    </svg>
  );
};

export default Business;
