import { Logo, MenuIcon, PhoneDesktop } from "svg";
import { useTranslation } from "react-i18next";
import Language from "./Language";

type PropsType = {
  setShowMenu: (bool: boolean) => void;
};

const Header: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="h-5 bg-gradient-to-b from-Sky to-light lg:h-[49px] lg:hidden"></div>
      <div className="mt-6 flex items-center gap-x-3 justify-center relative lg:hidden">
        <Logo />
        <div className="absolute right-9">
          <MenuIcon onClick={() => props.setShowMenu(true)} />
        </div>
      </div>
      <div className="hidden lg:flex w-full h-[49px] bg-[#242424] items-center justify-around">
        <ul className="text-base text-[#D0D0D0] flex items-center gap-x-4 xl:gap-x-7">
          <li>{t("home-page")}</li>
          <li>{t("about-us")}</li>
          <li>{t("news")}</li>
          <li>{t("partners")}</li>
          <li>{t("contact")}</li>
          <button className="outline-none border-none bg-blood rounded-lg uppercase w-24 h-9 text-white">
            sos
          </button>
        </ul>
        <Language />
        <div className="flex items-center gap-x-2 text-base text-[#D0D0D0]">
          <span>{t("call-center")}</span>
          <PhoneDesktop />
          <span>2 991 991</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
